var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticStyle: { height: "100%", "background-color": "#ffffff" } },
    [
      _c("head-layout", {
        attrs: {
          "head-title": "检查基本信息",
          "head-btn-options": _vm.headBtnOptions,
        },
        on: {
          "head-save": function ($event) {
            return _vm.headSave()
          },
          "head-quote": function ($event) {
            return _vm.headQuote()
          },
          "head-cancel": _vm.headCancel,
        },
      }),
      _c("div", { staticClass: "formContentBox" }, [
        _c(
          "div",
          { staticClass: "formMain" },
          [
            _c(
              "el-form",
              {
                ref: "dataForm",
                attrs: {
                  model: _vm.dataForm,
                  "label-width": "120px",
                  disabled: _vm.formType == "view",
                  rules: _vm.rules,
                },
              },
              [
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "配置编号", prop: "planCode" } },
                          [
                            _c(
                              "el-input",
                              {
                                attrs: {
                                  type: "text",
                                  placeholder: "请输入配置编码",
                                  maxlength: "64",
                                  "show-word-limit": "",
                                },
                                model: {
                                  value: _vm.dataForm.planCode,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.dataForm, "planCode", $$v)
                                  },
                                  expression: "dataForm.planCode",
                                },
                              },
                              [
                                _c(
                                  "span",
                                  { attrs: { slot: "append" }, slot: "append" },
                                  [
                                    _vm._v(
                                      "\n                  自动生成\n                  "
                                    ),
                                    _c("el-switch", {
                                      on: { change: _vm.prjCodeAutoChange },
                                      model: {
                                        value: _vm.prjCodeAuto,
                                        callback: function ($$v) {
                                          _vm.prjCodeAuto = $$v
                                        },
                                        expression: "prjCodeAuto",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "配置名称", prop: "planName" } },
                          [
                            _c("el-input", {
                              attrs: {
                                placeholder: "请输入配置名称",
                                clearable: "",
                                maxlength: "64",
                                "show-word-limit": "",
                              },
                              model: {
                                value: _vm.dataForm.planName,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dataForm, "planName", $$v)
                                },
                                expression: "dataForm.planName",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "关联项目",
                              prop: "organizationName",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "", disabled: "" },
                              model: {
                                value: _vm.dataForm.organizationName,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.dataForm,
                                    "organizationName",
                                    $$v
                                  )
                                },
                                expression: "dataForm.organizationName",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "检查类型", prop: "checkType" } },
                          [
                            _c(
                              "el-select",
                              {
                                attrs: {
                                  placeholder: "请选择检查类型",
                                  "value-key": "dictKey",
                                  clearable: "",
                                },
                                on: { change: _vm.checkTypeObj },
                                model: {
                                  value: _vm.checkTypeItem,
                                  callback: function ($$v) {
                                    _vm.checkTypeItem = $$v
                                  },
                                  expression: "checkTypeItem",
                                },
                              },
                              _vm._l(_vm.check_type, function (item) {
                                return _c("el-option", {
                                  key: item.dictKey,
                                  attrs: { label: item.dictValue, value: item },
                                })
                              }),
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "执行周期",
                              prop: "cycleFrequency",
                            },
                          },
                          [
                            _c("el-input", {
                              attrs: { placeholder: "请填写执行周期" },
                              on: { focus: _vm.cycleShowDialog },
                              model: {
                                value: _vm.dataForm.cycleFrequency,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dataForm, "cycleFrequency", $$v)
                                },
                                expression: "dataForm.cycleFrequency",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "是否启用", prop: "enableFlag" } },
                          [
                            _c("el-switch", {
                              attrs: {
                                "active-value": "Y",
                                "inactive-value": "N",
                              },
                              model: {
                                value: _vm.dataForm.enableFlag,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dataForm, "enableFlag", $$v)
                                },
                                expression: "dataForm.enableFlag",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "开始日期", prop: "startDate" } },
                          [
                            _c("el-date-picker", {
                              attrs: {
                                type: "date",
                                placeholder: "选择开始日期",
                                clearable: "",
                                "value-format": "yyyy-MM-dd HH:mm:ss",
                                "picker-options": _vm.pickerOptions,
                              },
                              model: {
                                value: _vm.dataForm.startDate,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dataForm, "startDate", $$v)
                                },
                                expression: "dataForm.startDate",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(
                      "el-col",
                      { attrs: { span: 8 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "结束日期", prop: "endDate" } },
                          [
                            _c("el-date-picker", {
                              attrs: {
                                type: "date",
                                "value-format": "yyyy-MM-dd HH:mm:ss",
                                "picker-options": _vm.pickerOptions,
                                placeholder: "选择结束日期",
                                clearable: "",
                              },
                              model: {
                                value: _vm.dataForm.endDate,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dataForm, "endDate", $$v)
                                },
                                expression: "dataForm.endDate",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _c(
                  "el-row",
                  [
                    _c(
                      "el-col",
                      { attrs: { span: 24 } },
                      [
                        _c(
                          "el-form-item",
                          { attrs: { label: "备注", prop: "remark" } },
                          [
                            _c("el-input", {
                              attrs: {
                                type: "textarea",
                                placeholder: "请填写备注",
                                maxlength: "50",
                                "show-word-limit": "",
                                rows: 2,
                                clearable: "",
                              },
                              model: {
                                value: _vm.dataForm.remark,
                                callback: function ($$v) {
                                  _vm.$set(_vm.dataForm, "remark", $$v)
                                },
                                expression: "dataForm.remark",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "shuttleBackBox" },
              [
                _c(
                  "el-tabs",
                  {
                    on: { "tab-click": _vm.handleClick },
                    model: {
                      value: _vm.activeName,
                      callback: function ($$v) {
                        _vm.activeName = $$v
                      },
                      expression: "activeName",
                    },
                  },
                  [
                    _c("el-tab-pane", {
                      attrs: {
                        label: "检查人员",
                        name: "thirdPartyOrganization",
                      },
                    }),
                    _c("el-tab-pane", {
                      attrs: { label: "检查项", name: "corePersonnel" },
                    }),
                    _c("el-tab-pane", {
                      attrs: {
                        label: "相关资料 ",
                        name: "thirdPartyPersonnel",
                      },
                    }),
                  ],
                  1
                ),
                _vm.activeName == "corePersonnel"
                  ? _c(
                      "el-container",
                      { staticClass: "main-box" },
                      [
                        _c("CommonTree", {
                          ref: "commonTreeCore",
                          attrs: {
                            treeTitle: "检查项分类",
                            isShowdig:
                              _vm.formType != "view" && _vm.dataForm.id,
                            isLeafIcons: true,
                            iconsFlied: "isObject",
                            searchTitle: _vm.jcxSearchTitle,
                            treeData: _vm.corePersonnelTreeData,
                            defaultProps: _vm.defaultProps,
                            showCheckbox: false,
                            treeExpand: true,
                          },
                          on: {
                            getTreeAdd: _vm.getTreeAdd,
                            getTreeDelete: _vm.getTreeDelete,
                            getTreeEdit: _vm.getTreeEdit,
                            getNodeClick: _vm.corePersonnelTreeNodeClick,
                          },
                        }),
                        _c(
                          "div",
                          { staticClass: "table-box" },
                          [
                            _c("head-layout", {
                              attrs: {
                                "head-btn-options":
                                  _vm.formType != "view" && _vm.dataForm.id
                                    ? _vm.corePersonnelHeadBtnOptions
                                    : [],
                                "head-title": "检查项",
                              },
                              on: {
                                "head-fx": _vm.headFx,
                                handleImport: _vm.handleImport,
                                "head-export": _vm.handleExport,
                                "head-remove": _vm.headDel,
                                "head-add": _vm.addjcx,
                              },
                            }),
                            _c("grid-layout", {
                              ref: "jcxPage",
                              attrs: {
                                "table-options": _vm.corePersonnelTableOption,
                                "data-total": _vm.page.total,
                                page: _vm.page,
                                "table-data": _vm.corePersonnelTableData,
                                "table-loading": _vm.jcxTableLoading,
                              },
                              on: {
                                "page-current-change": _vm.getJcx,
                                "page-size-change": _vm.getJcx,
                                "gird-handle-select-click": _vm.selectionChange,
                              },
                              scopedSlots: _vm._u(
                                [
                                  _vm.formType != "view"
                                    ? {
                                        key: "customBtn",
                                        fn: function ({ row }) {
                                          return [
                                            _c(
                                              "el-button",
                                              {
                                                staticStyle: {
                                                  margin: "0 3px",
                                                },
                                                attrs: {
                                                  type: "text",
                                                  size: "small",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.addjcx(row)
                                                  },
                                                },
                                              },
                                              [_vm._v("编辑")]
                                            ),
                                            _c(
                                              "el-button",
                                              {
                                                staticStyle: {
                                                  margin: "0 3px",
                                                },
                                                attrs: {
                                                  type: "text",
                                                  size: "small",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.rowDel([row])
                                                  },
                                                },
                                              },
                                              [_vm._v("删除")]
                                            ),
                                          ]
                                        },
                                      }
                                    : null,
                                ],
                                null,
                                true
                              ),
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.activeName == "thirdPartyOrganization"
                  ? _c(
                      "div",
                      { staticClass: "thirdPartyOrganization" },
                      [
                        _c("head-layout", {
                          attrs: {
                            "head-title": "检查人员",
                            "head-btn-options":
                              _vm.formType != "view" && _vm.dataForm.id
                                ? _vm.userBtnOptions
                                : [],
                          },
                          on: {
                            "head-add": function ($event) {
                              return _vm.headAdd1("jcry")
                            },
                            "head-remove": _vm.headRemove,
                          },
                        }),
                        _c("grid-layout", {
                          ref: "jcryPage",
                          attrs: {
                            "table-options":
                              _vm.thirdPartyOrganizationTableOption,
                            "table-data": _vm.thirdPartyOrganizationTableData,
                            "table-loading": _vm.tableLoading,
                            page: _vm.jcryPage,
                            total: _vm.jcryPage.total,
                          },
                          on: {
                            "page-current-change": _vm.getJcUser,
                            "page-size-change": _vm.getJcUser,
                            "gird-handle-select-click":
                              _vm.perosnselectionChange,
                          },
                          scopedSlots: _vm._u(
                            [
                              _vm.formType != "view"
                                ? {
                                    key: "customBtn",
                                    fn: function ({ row }) {
                                      return [
                                        _c(
                                          "el-button",
                                          {
                                            staticStyle: { margin: "0 3px" },
                                            attrs: {
                                              type: "text",
                                              size: "small",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.personRowDel([row])
                                              },
                                            },
                                          },
                                          [_vm._v("删除")]
                                        ),
                                      ]
                                    },
                                  }
                                : null,
                            ],
                            null,
                            true
                          ),
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm.activeName == "thirdPartyPersonnel"
                  ? _c(
                      "div",
                      [
                        _c(
                          "head-layout",
                          {
                            attrs: {
                              "head-title": "资料清单",
                              "head-btn-options": _vm.dataForm.id
                                ? _vm.fileBtnOptions
                                : [],
                            },
                            on: { headImport: _vm.headImport },
                          },
                          [
                            _vm.formType != "view" && _vm.dataForm.id
                              ? _c(
                                  "el-button",
                                  {
                                    attrs: { slot: "right", size: "mini" },
                                    on: { click: _vm.quote },
                                    slot: "right",
                                  },
                                  [_vm._v("知识库选择")]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                        _c("el-upload", {
                          staticClass: "upload-demo",
                          attrs: {
                            disabled: _vm.formType == "view",
                            "on-preview": _vm.handlePreview,
                            "file-list": _vm.xgzlFileList,
                            "on-remove": _vm.handleRemove,
                            "before-remove": _vm.beforeRemove,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm.jcxModel
        ? _c(
            "el-dialog",
            {
              directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
              attrs: { title: "检查项", visible: _vm.jcxModel, width: "40%" },
              on: {
                "update:visible": function ($event) {
                  _vm.jcxModel = $event
                },
              },
            },
            [
              _c("avue-form", {
                attrs: { option: _vm.jcxOption },
                on: {
                  "reset-change": _vm.resetChange1,
                  submit: _vm.handleSubmit1,
                },
                model: {
                  value: _vm.jcxForm,
                  callback: function ($$v) {
                    _vm.jcxForm = $$v
                  },
                  expression: "jcxForm",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _vm.jcxflModel
        ? _c(
            "el-dialog",
            {
              directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
              attrs: {
                title: "检查对象及分类新增",
                visible: _vm.jcxflModel,
                width: "50%",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.jcxflModel = $event
                },
              },
            },
            [
              _c("avue-form", {
                attrs: { option: _vm.jcxflOption },
                on: {
                  "reset-change": _vm.resetChange,
                  submit: _vm.handleSubmit,
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "typeName",
                      fn: function () {
                        return [
                          _vm.jcxflForm.objectType == "SD" ||
                          _vm.jcxflForm.id ||
                          _vm.jcxflForm.isObject == 0
                            ? _c("el-input", {
                                model: {
                                  value: _vm.jcxflForm.typeName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.jcxflForm, "typeName", $$v)
                                  },
                                  expression: "jcxflForm.typeName",
                                },
                              })
                            : _vm._e(),
                          _vm.jcxflForm.objectType == "EQ" &&
                          !_vm.jcxflForm.id &&
                          !_vm.jcxflForm.isObject == 0
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleTable("EQ")
                                    },
                                  },
                                },
                                [_vm._v("选择设备设施")]
                              )
                            : _vm._e(),
                          _vm.jcxflForm.objectType == "AREA" &&
                          !_vm.jcxflForm.id &&
                          !_vm.jcxflForm.isObject == 0
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleTable("qy")
                                    },
                                  },
                                },
                                [_vm._v("选择区域")]
                              )
                            : _vm._e(),
                          _vm.jcxflForm.objectType == "WORK" &&
                          !_vm.jcxflForm.id &&
                          !_vm.jcxflForm.isObject == 0
                            ? _c(
                                "el-button",
                                {
                                  attrs: { type: "primary" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.handleTable("ts")
                                    },
                                  },
                                },
                                [_vm._v("选择特殊作业")]
                              )
                            : _vm._e(),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  false,
                  4040243885
                ),
                model: {
                  value: _vm.jcxflForm,
                  callback: function ($$v) {
                    _vm.jcxflForm = $$v
                  },
                  expression: "jcxflForm",
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c("el-upload", {
        directives: [
          {
            name: "show",
            rawName: "v-show",
            value: _vm.hideUpload,
            expression: "hideUpload",
          },
        ],
        ref: "uploadRef",
        staticClass: "upload-demo",
        attrs: {
          action:
            "/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz/km",
          "show-file-list": false,
          "on-success": _vm.handleSuccess,
          headers: _vm.headers,
        },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "人员选择",
            visible: _vm.deptShow,
            width: "80%",
            top: "8vh",
          },
          on: {
            "update:visible": function ($event) {
              _vm.deptShow = $event
            },
          },
        },
        [
          _c("UserDetpDialog", {
            ref: "UserDetpDialog",
            attrs: { "dept-category": [2, 5] },
            on: { "select-data": _vm.selectData },
          }),
        ],
        1
      ),
      _vm.MultipShow
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "人员选择",
                visible: _vm.MultipShow,
                width: "80%",
                top: "8vh",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.MultipShow = $event
                },
              },
            },
            [
              _c("UserDeptMultipleDialog", {
                ref: "UserDeptMultipleDialog",
                on: {
                  closeDia: function ($event) {
                    _vm.MultipShow = false
                  },
                  "select-all-data": _vm.selectData,
                },
              }),
            ],
            1
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          attrs: { title: "项目选择", visible: _vm.deptShow1, width: "80%" },
          on: {
            "update:visible": function ($event) {
              _vm.deptShow1 = $event
            },
          },
        },
        [
          _c("DeptDialog", {
            ref: "DeptDialog",
            on: { "select-data": _vm.selectData1 },
          }),
        ],
        1
      ),
      _vm.fxyModel
        ? _c(
            "el-dialog",
            {
              directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
              staticClass: "dangerBox",
              attrs: {
                title: "风险库及措施",
                visible: _vm.fxyModel,
                width: "80%",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.fxyModel = $event
                },
              },
            },
            [
              _c(
                "el-container",
                { staticStyle: { height: "500px" } },
                [
                  _c("CommonTree", {
                    staticStyle: { "font-size": "13px" },
                    attrs: {
                      treeTitle: "风险源",
                      defaultProps: {
                        children: "children",
                        id: "id",
                        label: "sourceTypeName",
                      },
                      searchTitle: _vm.searchTitle,
                      showCheckbox: false,
                      treeData: _vm.treeData,
                      treeExpand: true,
                    },
                    on: { getNodeClick: _vm.handleNodeClick },
                  }),
                  _c(
                    "el-main",
                    [
                      _c("div", { staticClass: "searchTopBox" }, [
                        _c("div", { staticClass: "leftSearch" }, [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                width: "100%",
                                display: "flex",
                                "margin-bottom": "6px",
                              },
                            },
                            [
                              _c("el-input", {
                                staticClass: "searchBox",
                                attrs: {
                                  size: "mini",
                                  placeholder: "请输入风险名称",
                                },
                                model: {
                                  value: _vm.searchForm.riskName,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.searchForm, "riskName", $$v)
                                  },
                                  expression: "searchForm.riskName",
                                },
                              }),
                              _c("el-input", {
                                staticClass: "searchBox",
                                attrs: {
                                  size: "mini",
                                  placeholder: "请输入措施分类",
                                },
                                model: {
                                  value: _vm.searchForm.treatmentTypeName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.searchForm,
                                      "treatmentTypeName",
                                      $$v
                                    )
                                  },
                                  expression: "searchForm.treatmentTypeName",
                                },
                              }),
                              _c("el-input", {
                                staticClass: "searchBox",
                                attrs: {
                                  size: "mini",
                                  placeholder: "请输入措施描述",
                                },
                                model: {
                                  value: _vm.searchForm.treatmentDesc,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.searchForm,
                                      "treatmentDesc",
                                      $$v
                                    )
                                  },
                                  expression: "searchForm.treatmentDesc",
                                },
                              }),
                              _c("el-input", {
                                staticClass: "searchBox",
                                attrs: {
                                  size: "mini",
                                  placeholder: "请输入隐患排查内容",
                                },
                                model: {
                                  value: _vm.searchForm.hdCheckContext,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.searchForm,
                                      "hdCheckContext",
                                      $$v
                                    )
                                  },
                                  expression: "searchForm.hdCheckContext",
                                },
                              }),
                              _c(
                                "el-select",
                                {
                                  staticClass: "searchBox",
                                  attrs: {
                                    placeholder: "请输入管控层级",
                                    size: "mini",
                                  },
                                  model: {
                                    value: _vm.searchForm.controlLevel,
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.searchForm,
                                        "controlLevel",
                                        $$v
                                      )
                                    },
                                    expression: "searchForm.controlLevel",
                                  },
                                },
                                _vm._l(_vm.riskControlLevel, function (item) {
                                  return _c("el-option", {
                                    key: item.dictKey,
                                    attrs: {
                                      label: item.dictValue,
                                      value: item.dictKey,
                                    },
                                  })
                                }),
                                1
                              ),
                            ],
                            1
                          ),
                          _c(
                            "div",
                            {
                              staticStyle: {
                                width: "100%",
                                display: "flex",
                                "margin-bottom": "6px",
                              },
                            },
                            [
                              _c("el-input", {
                                staticClass: "searchBox",
                                attrs: {
                                  size: "mini",
                                  placeholder: "请输入负责人员",
                                },
                                model: {
                                  value: _vm.searchForm.respUserName,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.searchForm,
                                      "respUserName",
                                      $$v
                                    )
                                  },
                                  expression: "searchForm.respUserName",
                                },
                              }),
                              _c(
                                "div",
                                { staticClass: "rightSearch" },
                                [
                                  _c(
                                    "el-button-group",
                                    [
                                      _c("el-button", {
                                        attrs: {
                                          size: "mini",
                                          type: "primary",
                                          icon: "el-icon-search",
                                        },
                                        on: { click: _vm.searchChange },
                                      }),
                                      _c("el-button", {
                                        staticStyle: { height: "28px" },
                                        attrs: {
                                          size: "mini",
                                          icon: "reset-refresh icon-refresh",
                                        },
                                        on: { click: _vm.searchReset },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ]),
                      ]),
                      _c("grid-layout", {
                        ref: "gridLayOut",
                        attrs: {
                          "table-options": _vm.fxyOption,
                          "data-total": _vm.fxxPage.total,
                          page: _vm.fxxPage,
                          "table-data": _vm.fxyTableData,
                          "table-loading": _vm.fxyTableLoading,
                        },
                        on: {
                          "gird-handle-select-click": _vm.selectionChange,
                          "page-size-change": _vm.handleSizeChange,
                          "page-current-change": _vm.handleCurrentChange,
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "avue-dialog__footer" },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.fxyModel = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                  _c(
                    "el-button",
                    {
                      attrs: { type: "primary" },
                      on: { click: _vm.handleFxySave },
                    },
                    [_vm._v("确 定")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.cycleShow
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "周期配置",
                visible: _vm.cycleShow,
                width: "30%",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.cycleShow = $event
                },
              },
            },
            [
              _c(
                "el-form",
                {
                  ref: "cycleForm",
                  attrs: {
                    model: _vm.cycleForm,
                    "label-width": "100px",
                    rules: _vm.cycleRules,
                  },
                },
                [
                  _c(
                    "el-form-item",
                    { attrs: { label: "类型", prop: "durationType" } },
                    [
                      _c(
                        "el-radio-group",
                        {
                          on: { change: _vm.durationTypeChange },
                          model: {
                            value: _vm.cycleForm.durationType,
                            callback: function ($$v) {
                              _vm.$set(_vm.cycleForm, "durationType", $$v)
                            },
                            expression: "cycleForm.durationType",
                          },
                        },
                        _vm._l(_vm.cycleDicData, function (item) {
                          return _c(
                            "el-radio",
                            {
                              key: item.dictKey,
                              attrs: { label: item.dictKey },
                            },
                            [_vm._v(_vm._s(item.dictValue))]
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  ),
                  _vm.cycleForm.durationType == "CRON"
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "CRON表达式", prop: "cronStr" } },
                        [
                          _c("el-input", {
                            attrs: { placeholder: "请输入CRON表达式" },
                            model: {
                              value: _vm.cycleForm.cronStr,
                              callback: function ($$v) {
                                _vm.$set(_vm.cycleForm, "cronStr", $$v)
                              },
                              expression: "cycleForm.cronStr",
                            },
                          }),
                        ],
                        1
                      )
                    : _vm._e(),
                  _c(
                    "el-row",
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _vm.cycleForm.durationType == "NORMAL"
                            ? _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "时间间隔",
                                    prop: "planInterval",
                                  },
                                },
                                [
                                  _c("el-input-number", {
                                    attrs: { label: "时间间隔", min: "0" },
                                    model: {
                                      value: _vm.cycleForm.planInterval,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.cycleForm,
                                          "planInterval",
                                          $$v
                                        )
                                      },
                                      expression: "cycleForm.planInterval",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _vm.cycleForm.durationType == "NORMAL"
                            ? _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "单位",
                                    prop: "intervalUnit",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: { placeholder: "请选择单位" },
                                      on: { change: _vm.intervalUnitChange },
                                      model: {
                                        value: _vm.cycleForm.intervalUnit,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.cycleForm,
                                            "intervalUnit",
                                            $$v
                                          )
                                        },
                                        expression: "cycleForm.intervalUnit",
                                      },
                                    },
                                    _vm._l(_vm.unitData, function (item) {
                                      return _c("el-option", {
                                        key: item.dictKey,
                                        attrs: {
                                          label: item.dictValue,
                                          value: item.dictKey,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _vm.cycleForm.intervalUnit == "week"
                            ? _c(
                                "el-form-item",
                                {
                                  attrs: { label: "选择周", prop: "startWeek" },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: { placeholder: "请选择周几" },
                                      model: {
                                        value: _vm.cycleForm.startWeek,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.cycleForm,
                                            "startWeek",
                                            $$v
                                          )
                                        },
                                        expression: "cycleForm.startWeek",
                                      },
                                    },
                                    _vm._l(_vm.weekMap, function (item) {
                                      return _c("el-option", {
                                        key: item.value,
                                        attrs: {
                                          label: item.label,
                                          value: item.value,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _vm.cycleForm.intervalUnit == "month"
                            ? _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "选择月",
                                    prop: "startMonth",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: { placeholder: "请选择月份" },
                                      on: { change: _vm.monthChange },
                                      model: {
                                        value: _vm.cycleForm.startMonth,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.cycleForm,
                                            "startMonth",
                                            $$v
                                          )
                                        },
                                        expression: "cycleForm.startMonth",
                                      },
                                    },
                                    _vm._l(12, function (i) {
                                      return _c("el-option", {
                                        key: i,
                                        attrs: { label: i, value: i },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _vm.cycleForm.intervalUnit == "month" &&
                          _vm.cycleForm.startMonth
                            ? _c(
                                "el-form-item",
                                {
                                  attrs: { label: "选择日", prop: "startDay" },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: { placeholder: "请选择日" },
                                      model: {
                                        value: _vm.cycleForm.startDay,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.cycleForm,
                                            "startDay",
                                            $$v
                                          )
                                        },
                                        expression: "cycleForm.startDay",
                                      },
                                    },
                                    _vm._l(_vm.nowDays, function (i) {
                                      return _c("el-option", {
                                        key: i,
                                        attrs: { label: i, value: i },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _vm.cycleForm.intervalUnit == "year"
                            ? _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "选择日期",
                                    prop: "cycleTime",
                                  },
                                },
                                [
                                  _c("el-date-picker", {
                                    attrs: {
                                      type: "date",
                                      "picker-options": _vm.pickerOptions,
                                      "value-format": "yyyy-MM-dd",
                                      placeholder: "选择日期",
                                    },
                                    model: {
                                      value: _vm.cycleForm.cycleTime,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.cycleForm,
                                          "cycleTime",
                                          $$v
                                        )
                                      },
                                      expression: "cycleForm.cycleTime",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _vm.cycleForm.durationType == "NORMAL"
                            ? _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "起始(小时)",
                                    prop: "startTimeh",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: { placeholder: "请选择" },
                                      model: {
                                        value: _vm.cycleForm.startTimeh,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.cycleForm,
                                            "startTimeh",
                                            $$v
                                          )
                                        },
                                        expression: "cycleForm.startTimeh",
                                      },
                                    },
                                    _vm._l(_vm.hoptions, function (item) {
                                      return _c("el-option", {
                                        key: item.value,
                                        attrs: {
                                          label: item.label,
                                          value: item.value,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 12 } },
                        [
                          _vm.cycleForm.durationType == "NORMAL"
                            ? _c(
                                "el-form-item",
                                {
                                  attrs: {
                                    label: "起始(分)",
                                    prop: "startTimem",
                                  },
                                },
                                [
                                  _c(
                                    "el-select",
                                    {
                                      attrs: { placeholder: "请选择" },
                                      model: {
                                        value: _vm.cycleForm.startTimem,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.cycleForm,
                                            "startTimem",
                                            $$v
                                          )
                                        },
                                        expression: "cycleForm.startTimem",
                                      },
                                    },
                                    _vm._l(_vm.moptions, function (item) {
                                      return _c("el-option", {
                                        key: item.value,
                                        attrs: {
                                          label: item.label,
                                          value: item.value,
                                        },
                                      })
                                    }),
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                "span",
                { attrs: { slot: "footer" }, slot: "footer" },
                [
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.treeDialogLoading,
                          expression: "treeDialogLoading",
                        },
                      ],
                      attrs: { size: "small" },
                      on: {
                        click: function ($event) {
                          _vm.cycleShow = false
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t(`cip.cmn.btn.celBtn`)) +
                          "\n      "
                      ),
                    ]
                  ),
                  _c(
                    "el-button",
                    {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.treeDialogLoading,
                          expression: "treeDialogLoading",
                        },
                      ],
                      attrs: { size: "small", type: "primary" },
                      on: { click: _vm.handleCycleFormSave },
                    },
                    [_vm._v("\n        保存\n      ")]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm.quoteModel
        ? _c(
            "el-dialog",
            {
              directives: [{ name: "dialogDrag", rawName: "v-dialogDrag" }],
              attrs: {
                title: "引用检查活动",
                visible: _vm.quoteModel,
                width: "80%",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.quoteModel = $event
                },
              },
            },
            [
              _c(
                "div",
                { staticStyle: { width: "100%", height: "520px" } },
                [
                  _c("grid-head-layout", {
                    ref: "searchForm",
                    attrs: { "search-columns": _vm.QuoteSearchColumns },
                    on: {
                      "grid-head-search": _vm.searchChangeQuote,
                      "grid-head-empty": _vm.searchResetQuote,
                    },
                  }),
                  _c("grid-layout", {
                    ref: "gridLayOut",
                    attrs: {
                      "table-options": _vm.hdOption,
                      "data-total": _vm.hdtableTotal,
                      "table-data": _vm.hdTableData,
                      "table-loading": _vm.hdTableLoading,
                      page: _vm.pageQuote,
                    },
                    on: {
                      "gird-handle-select-click": _vm.selectionChangeQuote,
                      "page-current-change": _vm.getQuotes,
                      "page-size-change": _vm.getQuotes,
                    },
                    scopedSlots: _vm._u(
                      [
                        {
                          key: "checkTypeName",
                          fn: function ({ row }) {
                            return [
                              _c("div", [
                                _vm._v(
                                  _vm._s(_vm.getCheckTypeName(row.checkType))
                                ),
                              ]),
                            ]
                          },
                        },
                        {
                          key: "customBtn",
                          fn: function ({ row }) {
                            return [
                              _c(
                                "el-button",
                                {
                                  staticStyle: { margin: "0 3px" },
                                  attrs: { type: "text", size: "small" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.selectQuote(row)
                                    },
                                  },
                                },
                                [_vm._v("选择")]
                              ),
                            ]
                          },
                        },
                      ],
                      null,
                      false,
                      828268869
                    ),
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "avue-dialog__footer" },
                [
                  _c(
                    "el-button",
                    {
                      on: {
                        click: function ($event) {
                          _vm.quoteModel = false
                        },
                      },
                    },
                    [_vm._v("取 消")]
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
      _c(
        "el-dialog",
        {
          directives: [{ name: "dialog-drag", rawName: "v-dialog-drag" }],
          attrs: {
            title: "附件预览",
            modal: true,
            "modal-append-to-body": false,
            "close-on-click-modal": false,
            visible: _vm.showFileDialog,
            width: "70%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showFileDialog = $event
            },
          },
        },
        [
          _c("iframe", {
            ref: "fileIframe",
            staticStyle: { width: "100%", height: "500px" },
            attrs: { src: _vm.fileUrl, frameborder: "0" },
          }),
          _c(
            "span",
            { attrs: { slot: "footer" }, slot: "footer" },
            [
              _c(
                "el-button",
                {
                  attrs: { size: "small" },
                  on: {
                    click: function ($event) {
                      _vm.showFileDialog = false
                    },
                  },
                },
                [_vm._v("取消")]
              ),
            ],
            1
          ),
        ]
      ),
      _vm.WorkTicketShow
        ? _c("WorkTicket", {
            ref: "WorkTicket",
            on: { selectWorkTicket: _vm.selectWorkTicket },
          })
        : _vm._e(),
      _c("riskArea", {
        ref: "riskArea",
        attrs: { multiple: "" },
        on: { callback: _vm.selectWorkTicket },
      }),
      _c(
        "el-dialog",
        {
          attrs: {
            top: "9vh",
            title: "知识库选择",
            visible: _vm.showKnowledgeSearchDialog,
            width: "80%",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showKnowledgeSearchDialog = $event
            },
          },
        },
        [
          _c("KnowledgeSearchDialog", {
            ref: "knowledgeSearchDialog",
            on: {
              "close-dialog": _vm.closeKnowledgeSearchDialog,
              "select-data": _vm.selectFile,
            },
          }),
        ],
        1
      ),
      _c("equipmentDialog", {
        ref: "equipmentDialog",
        on: { callback: _vm.selectWorkTicket },
      }),
      _vm.excelBox
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "检查项导入",
                "append-to-body": "",
                visible: _vm.excelBox,
                width: "555px",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.excelBox = $event
                },
              },
            },
            [
              _c(
                "div",
                { staticClass: "import" },
                [
                  _c(
                    "avue-form",
                    {
                      attrs: {
                        option: _vm.excelOption,
                        "upload-after": _vm.uploadAfter,
                      },
                      model: {
                        value: _vm.excelOption.excelForm,
                        callback: function ($$v) {
                          _vm.$set(_vm.excelOption, "excelForm", $$v)
                        },
                        expression: "excelOption.excelForm",
                      },
                    },
                    [
                      _c(
                        "template",
                        { slot: "excelTemplate" },
                        [
                          _c(
                            "el-button",
                            {
                              attrs: { type: "primary" },
                              on: { click: _vm.handleTemplate },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.$t("cip.plat.sys.user.field.download")
                                  )
                              ),
                              _c("i", {
                                staticClass: "el-icon-download el-icon--right",
                              }),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                ],
                1
              ),
            ]
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }